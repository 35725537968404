import React, { useContext } from "react"
import styled from "styled-components"

import media from "../../styles/media"
import TopFooter from "./TopFooter"
import BottomFooter from "./BottomFooter"

import { HeaderContext } from "../Header/context"

const Container = styled.footer`
  background-color: var(--concrete);
  padding: calc(1.5 * var(--gutter-l)) calc(1.5 * var(--gutter-s))
    var(--gutter-s);
  ${media.tablet`
    padding: calc(1.5 * var(--gutter-l)) calc(1.2 * var(--gutter-l))
      var(--gutter-s);
  `}
  ${media.laptop`
    padding: calc(1.5 * var(--gutter-l)) calc(1.5 * var(--gutter-l))
      var(--gutter-s);
  `}
`

const Spacer = styled.div`
  height: 100px;
  ${media.tablet`
    height: 200px;
  `}
`

const Footer = () => {
  const context = useContext(HeaderContext)
  return (
    <Container>
      <TopFooter toggleOpen={context.toggleOpen} />
      <Spacer />
      <BottomFooter />
    </Container>
  )
}

export default Footer
