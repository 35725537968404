import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import media from "../../styles/media"

import capeElectric from "../../images/sponsors/cape-electric.jpg"
import luckySupply from "../../images/sponsors/lucky-supply.jpg"
import logo from "../../images/saitc.jpg"

const Container = styled.div`
  display: grid;
  grid-template: auto auto / 1fr;
  ${media.tablet`
    grid-template: auto / 1fr 1fr;
  `}
`

const LeftPanel = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  ${media.tablet`
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    align-items: flex-start;
  `}
`

const Copyright = styled.span`
  font-size: 0.7rem;
  text-align: center;
  ${media.tablet`
    text-align: left;
  `}
`

const Sponsors = styled.div`
  h4 {
    margin-bottom: var(--gutter-s);
    text-align: center;
  }
  .links {
    display: flex;
    margin-bottom: var(--gutter-s);
    a {
      img {
        height: 60px;
        width: auto;
      }
      &:nth-child(2) {
        padding: 0 var(--gutter-s);
      }
    }
  }
  ${media.tablet`
    h4 {
      text-align: left;
    }
    .links {
      a {
        img {
          height: 70px;
        }
      }
    }
  `}
`

const RightPanel = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--gutter-m);
  ${media.tablet`
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 0;
  `}
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: var(--gutter-s);
  a {
    img {
      height: 75px;
      width: auto;
    }
  }
  ${media.tablet`
    margin-bottom: var(--gutter-s);
    a {
      img {
        height: 75px;
      }
    }
  `}
`

const LinksContainer = styled.div`
  a {
    font-size: 0.7rem;
    padding: 0 var(--gutter-s);
  }
  ${media.tablet`
    a {
      padding: 0 0 0 var(--gutter-s);
      transition: var(--transition-medium);
      &:hover {
        color: var(--carnation);
      }
    }
  `}
`

const BottomFooter = () => {
  return (
    <Container>
      <LeftPanel>
        <Sponsors>
          <h4 className="font-black">in association with</h4>
          <div className="links">
            <a
              href="http://capeelectrictattoo.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <img src={capeElectric} alt="Cape Electric Tattoo" />
            </a>
            <a
              href="https://www.luckysupply.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <img src={luckySupply} alt="Cape Electric Tattoo" />
            </a>
          </div>
        </Sponsors>
        <Copyright>
          © south african international tattoo convention. all rights reserved.
        </Copyright>
      </LeftPanel>
      <RightPanel>
        <LogoContainer>
          <Link to="/">
            <img
              src={logo}
              alt="South African International Tattoo Convention"
            />
          </Link>
        </LogoContainer>
        <LinksContainer>
          <Link to="/terms" className="mouse-link">
            {" "}
            terms{" "}
          </Link>
          {/* <Link to="/" className="mouse-link">
            {" "}
            privacy{" "}
          </Link> */}
          <a
            href="mailto: waldo@southafricantattooconvention.com"
            className="mouse-link"
          >
            {" "}
            contact{" "}
          </a>
        </LinksContainer>
      </RightPanel>
    </Container>
  )
}

export default BottomFooter
