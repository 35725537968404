import React, { useContext } from "react"
import PropTypes from "prop-types"
import { isBrowser } from "react-device-detect"

import Navbar from "../Header/Navbar"
import SiteMenu from "../Header/SiteMenu"
import Footer from "../Footer"
import Cursor from "../elements/cursor"

import { HeaderContext } from "../Header/context"

const Layout = ({ children, pageMenu }) => {
  const context = useContext(HeaderContext)

  return (
    <div>
      <Navbar pageMenu={pageMenu} toggleOpen={context.toggleOpen} />
      <SiteMenu isOpen={context.isOpen} toggleOpen={context.toggleOpen} />
      <main> {children} </main>
      <Footer />
      {isBrowser && <Cursor />}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageMenu: PropTypes.array,
}

export default Layout
