/**
 * @flow
 */

import React from "react"
import { ReactNode } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import media from "../../styles/media"

// type Props = {
//   toggleOpen: ReactNode,
// }

const Container = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  display: grid;
  grid-template: auto / 1fr auto;
  align-items: flex-end;
  ${media.tablet`
    grid-template: auto / auto 1fr auto auto;
  `}
`

const Logo = styled.div`
  a {
    font-size: 1.25rem;
    line-height: 1;
    &.hidden-text {
      opacity: 0;
    }
  }
  ${media.tablet`
    a {
      font-size: 1.5rem;
    }
  `}
  ${media.laptop`
    a {
      font-size: 1.75rem;
    }
  `}
`

const ApplyContainer = styled.div`
  display: none;
  a {
    font-size: 1.25rem;
    .link-primary {
      position: relative;
      color: var(--carnation);
      overflow: hidden;
      display: inline-block;
      &:after {
        content: "";
        position: absolute;
        background-color: var(--carnation);
        width: 100%;
        left: 0;
        bottom: 0;
        height: 2px;
        transition: all 250ms ease-out;
        transform: translateX(0%);
      }
      &:hover {
        &:after {
          transform: translateX(50%);
        }
      }
    }
  }
  ${media.tablet`
    display: grid;
    grid-template: auto / 1fr 1fr;
    justify-items: center;
    a {
      .link-primary {
        margin-left: 1em;
        font-family: 'circular-std-bold';
      }
    }
  `}
`

const Icon = styled.span`
  position: relative;
  font-size: 1rem;
  margin-left: var(--gutter-s);
  overflow: hidden;
  font-size: 1.25rem;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--black);
    left: 0;
    bottom: 0;
    transition: all 250ms ease-out;
    transform: translateX(0);
  }
  &:hover {
    &:after {
      transform: translateX(50%);
    }
  }
`

const SocialContainer = styled.div`
  display: none;
  a {
    font-size: 1.25rem;
    color: var(--black);
    padding: 0 var(--gutter-s);
  }
  ${media.tablet`
    display: flex;
    a {
      &:hover {
        color: var(--carnation);
      }
    }
  `}
`

const BuyTicket = styled.a`
  text-transform: uppercase;
  display: none;
  font-family: "circular-std-black";
  text-align: center;
  color: var(--carnation);
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    height: 2px;
    background-color: var(--carnation);
    width: 100%;
    left: 0;
    bottom: 0;
    transition: var(--transition-medium);
  }
  &:hover {
    &:after {
      transform: translateX(50%);
    }
  }
  ${media.laptop`
    font-size: 1.75rem;
    display: inline-block;
  `}
`

const TopFooter = ({ toggleOpen }) => {
  return (
    <Container>
      <Logo>
        <Link to="/" className="font-black mouse-link hidden-text">
          south <br />
          african <br />
          international <br />
          tattoo <br />
          convention
        </Link>
      </Logo>

      <ApplyContainer>
        {/* <BuyTicket
          href="https://www.webtickets.co.za/v2/event.aspx?itemid=1491704951"
          target="blank"
        >
          Buy Tickets &#8599;
        </BuyTicket> */}
      </ApplyContainer>

      <SocialContainer>
        <a
          href="https://www.facebook.com/satattooconvention/"
          target="_blank"
          rel="noopener noreferrer"
          className="font-black transition-medium mouse-link"
        >
          facebook
        </a>
        <a
          href="https://www.instagram.com/sa_tattooconvention/"
          target="_blank"
          rel="noopener noreferrer"
          className="font-black transition-medium mouse-link"
        >
          instagram
        </a>
      </SocialContainer>

      <Icon className="font-black mouse-link" onClick={toggleOpen}>
        menu
      </Icon>
    </Container>
  )
}

export default TopFooter
