/**
 * @flow
 */

import React, { useEffect, useRef, memo, useState } from "react"
import { ReactNode } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { TweenLite, Expo } from "gsap"

import media from "../../styles/media"

// type Props = {
//   isOpen: boolean,
//   toggleOpen: ReactNode,
// }

const Container = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 1);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  transform: translate(100%, 0);
  display: grid;
  grid-template: 1fr / 1fr;
  ${media.tablet``}
`

const InnerMenu = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.tablet``}
`

const LinksContainer = styled.ul`
  margin-bottom: var(--gutter-m);
  li {
    font-size: 1rem;
    list-style: none;
    text-align: center;
    transform: ${props =>
      props.active ? `translateY(0%)` : `translateY(100%)`};
    opacity: ${props => (props.active ? "1" : "0")};
    a {
      font-size: 2.5rem;
      line-height: 1;
      color: white;
    }
    &:nth-child(1) {
      transition: ${props =>
        props.active ? `all 500ms ease-out 500ms` : `all 500ms ease-out 300ms`};
    }
    &:nth-child(2) {
      transition: ${props =>
        props.active ? `all 500ms ease-out 525ms` : `all 500ms ease-out 275ms`};
    }
    &:nth-child(3) {
      transition: ${props =>
        props.active ? `all 500ms ease-out 550ms` : `all 500ms ease-out 250ms`};
    }
    &:nth-child(4) {
      transition: ${props =>
        props.active ? `all 500ms ease-out 575ms` : `all 500ms ease-out 225ms`};
    }
    &:nth-child(5) {
      transition: ${props =>
        props.active ? `all 500ms ease-out 600ms` : `all 500ms ease-out 200ms`};
    }
    &:nth-child(6) {
      transition: ${props =>
        props.active ? `all 500ms ease-out 625ms` : `all 500ms ease-out 175ms`};
    }
    &:nth-child(7) {
      transition: ${props =>
        props.active ? `all 500ms ease-out 650ms` : `all 500ms ease-out 150ms`};
    }
    &:nth-child(8) {
      transition: ${props =>
        props.active ? `all 500ms ease-out 650ms` : `all 500ms ease-out 125ms`};
    }
    &:nth-child(9) {
      transition: ${props =>
        props.active ? `all 500ms ease-out 650ms` : `all 500ms ease-out 100ms`};
    }
  }
  ${media.tablet`
    li {
      a {
        font-size: 3.5rem;
        &:hover {
          color: var(--carnation);
        }
      }
    }
  `}
`

const TicketContainer = styled.div`
  display: flex;
  margin-bottom: var(--gutter-m);
  transition: ${props =>
    props.active ? `all 500ms ease-out 900ms` : `all 500ms ease-out 0ms`};
  transform: ${props => (props.active ? `translateY(0%)` : `translateY(100%)`)};
  opacity: ${props => (props.active ? "1" : "0")};
  a {
    position: relative;
    margin: 0 var(--gutter-s);
    color: var(--carnation);
    font-size: 1.25rem;
    overflow: hidden;
    span {
      padding-left: 5px;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: 100%;
      transition: var(--transition-medium);
      background-color: var(--carnation);
      transform: translateX(0%);
    }
    &.mouse-link {
      color: var(--carnation);
      font-size: 0.8em;
    }
  }
  ${media.tablet`
    a {
      &:hover {
        &:after {
          transform: translateX(50%);
        }
      }
      &.mouse-link {
        font-size: 2em;
      }
    }
  `}
`

const SocialContainer = styled.div`
  display: flex;
  transition: ${props =>
    props.active ? `all 500ms ease-out 1200ms` : `all 500ms ease-out 0ms`};
  transform: ${props => (props.active ? `translateY(0%)` : `translateY(100%)`)};
  opacity: ${props => (props.active ? "1" : "0")};
  a {
    color: var(--white);
    font-size: 1.25rem;
    padding: 0 var(--gutter-s);
  }
  ${media.tablet`
    a {
      &:hover {
        color: var(--carnation);
      }
    }
  `}
`

const Icon = styled.span`
  display: inline-block;
  text-align: right;
  position: absolute;
  top: var(--gutter-s);
  right: var(--gutter-s);
  color: var(--tundora);
  font-size: 1.25rem;
  ${media.tablet`
    top: var(--gutter-m);
    right: var(--gutter-l);
  `}
`

const SiteMenu = ({ isOpen, toggleOpen }) => {
  const menu = useRef(null)
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (isOpen) {
      TweenLite.to(menu.current, 1, {
        x: "0%",
        ease: Expo.easeInOut,
        onComplete: setActive(true),
      })
    } else {
      TweenLite.to(menu.current, 1, {
        x: "100%",
        ease: Expo.easeInOut,
        onComplete: setActive(false),
      })
    }
  }, [isOpen])

  return (
    <Container ref={menu}>
      <InnerMenu>
        <LinksContainer active={active}>
          <li>
            <Link
              to="/"
              className="font-black transition-medium mouse-link"
              onClick={toggleOpen}
            >
              home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="font-black transition-medium mouse-link"
              onClick={toggleOpen}
            >
              about
            </Link>
          </li>
          <li>
            <Link
              to="/#tickets"
              className="font-black transition-medium mouse-link"
              onClick={toggleOpen}
            >
              tickets
            </Link>
          </li>
          <li>
            <Link
              to="/artists"
              className="font-black transition-medium mouse-link"
              onClick={toggleOpen}
            >
              artists
            </Link>
          </li>
          <li>
            <Link
              to="/#venue"
              className="font-black transition-medium mouse-link"
              onClick={toggleOpen}
            >
              venue
            </Link>
          </li>
          <li>
            <Link
              to="/marketplace"
              className="font-black transition-medium mouse-link"
              onClick={toggleOpen}
            >
              marketplace
            </Link>
          </li>
          <li>
            <Link
              to="/entertainment"
              className="font-black transition-medium mouse-link"
              onClick={toggleOpen}
            >
              entertainment
            </Link>
          </li>
          <li>
            <Link
              to="/apply"
              className="font-black transition-medium mouse-link"
              onClick={toggleOpen}
            >
              apply
            </Link>
          </li>
          <li>
            <Link
              to="/culture"
              className="font-black transition-medium mouse-link"
              onClick={toggleOpen}
            >
              gallery
            </Link>
          </li>
          {/* <li>
            <a
              href="https://shop.southafricantattooconvention.com/"
              className="font-black transition-medium mouse-link"
              target="_blank"
              rel="noreferrer noopener"
            >
              shop
            </a>
          </li> */}
        </LinksContainer>
        <TicketContainer active={active}>
          {/* <a
            href="https://www.webtickets.co.za/v2/event.aspx?itemid=1491704951"
            target="blank"
            className="mouse-link"
          >
            buy tickets
            <span>&#8599;</span>
          </a> */}
          <Link to="/getting-tattooed" className="mouse-link">
            how to get tattooed
            <span>&#8594;</span>
          </Link>
        </TicketContainer>
        <SocialContainer active={active}>
          <a
            href="https://www.facebook.com/satattooconvention/"
            target="_blank"
            rel="noopener noreferrer"
            className="transition-medium mouse-link"
          >
            facebook
          </a>
          <a
            href="https://www.instagram.com/sa_tattooconvention/"
            target="_blank"
            rel="noopener noreferrer"
            className="transition-medium mouse-link"
          >
            instagram
          </a>
          <a
            href="mailto: waldo@southafricantattooconvention.com"
            rel="noopener noreferrer"
            className="transition-medium mouse-link"
          >
            contact
          </a>
        </SocialContainer>
      </InnerMenu>
      <Icon onClick={toggleOpen} className="font-black mouse-link">
        close
      </Icon>
    </Container>
  )
}

export default memo<Props>(SiteMenu)
