/**
 * @flow
 */

import React, { memo } from "react"
import { ReactNode } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Link as ScrollLink } from "react-scroll"

import media from "../../styles/media"

// type Props = {
//   pageMenu: Array<String>,
//   isOpen: boolean,
//   toggleOpen: ReactNode,
// }

const Container = styled.header`
  position: relative;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  display: grid;
  grid-template: ${props =>
    props.pageMenu ? "auto auto / 1fr auto" : "auto / 1fr auto"};
  align-items: center;
  padding: var(--gutter-s) calc(1.5 * var(--gutter-s));
  ${media.tablet`
    grid-template: ${props =>
      props.pageMenu ? "auto / 1fr auto auto" : "auto / 1fr auto"};
    padding: var(--gutter-s) calc(1.2 * var(--gutter-l));
  `}
  ${media.laptop`
    grid-template: ${props =>
      props.pageMenu ? "auto / 1fr auto auto" : "auto / 1fr auto"};
    padding: var(--gutter-s) calc(1.5 * var(--gutter-l));
  `}
`

const Logo = styled.div`
  a {
    font-size: 1.25rem;
    line-height: 1;
    color: var(--carnation);
    &.hidden-text {
      opacity: 0;
    }
  }
  ${media.tablet`
    a {
      font-size: 1.4rem;
    }
  `}
  ${media.laptop`
    a {
      font-size: 1.75rem;
    }
  `}
`

const Icon = styled.span`
  position: relative;
  font-size: 1.25rem;
  margin-left: var(--gutter-s);
  /* cursor: pointer; */
  overflow: hidden;
  color: var(--carnation);
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    color: var(--carnation);
    left: 0;
    bottom: 0;
    transition: all 250ms ease-out;
    transform: translateX(0%);
  }
  &:hover {
    &:after {
      transform: translateX(50%);
    }
  }
  ${media.tablet`
    font-size: 1.4rem;
  `}
  ${media.laptop`
    font-size: 1.75rem;
  `}
`

const PageMenu = styled.ul`
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: flex;
  justify-content: center;
  li {
    list-style: none;
    padding-top: var(--gutter-s);
    a {
      display: inline-block;
      padding: var(--gutter-s);
      font-size: 1.25rem;
    }
  }
  ${media.tablet`
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    li {
      padding-top: 0;
      a {
        /* cursor: pointer; */
        font-size: 1.4rem;
        transition: var(--transition-medium);
        &:hover {
          color: var(--carnation);
        }
      }
    }
  `}
  ${media.laptop`
    li {
      a {
        /* cursor: pointer; */
        font-size: 1.75rem;
      }
    }
  `}
`

const Navbar = ({ pageMenu, toggleOpen }) => {
  const renderPageMenu = () => {
    if (pageMenu) {
      return pageMenu.map((link, i) => {
        return (
          <li key={i}>
            <ScrollLink
              to={link}
              className="font-black mouse-link"
              spy={true}
              smooth={true}
              offset={80}
              duration={500}
            >
              {link}
            </ScrollLink>
          </li>
        )
      })
    }
  }
  return (
    <Container pageMenu={pageMenu}>
      <Logo>
        <Link to="/" className="font-black mouse-link">
          south <br />
          african <br />
          international <br />
          tattoo <br />
          convention
        </Link>
      </Logo>
      {pageMenu && <PageMenu> {renderPageMenu()} </PageMenu>}
      <Icon className="font-black mouse-link" onClick={toggleOpen}>
        menu
      </Icon>
    </Container>
  )
}

export default memo<Props>(Navbar)
