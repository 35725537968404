import React, { useState, useEffect, useRef } from "react"
import { isBrowser } from "react-device-detect"
import { TweenLite } from "gsap"
import styled from "styled-components"

import media from "../../styles/media"

const Container = styled.div`
  /* display: none; */
  /* ${media.tablet``} */
  background-color: rgba(255, 255, 255, 1);
  mix-blend-mode: difference;
  pointer-events: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: -3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(1);
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  svg {
    fill: #fff;
    width: 0.1em;
    height: 0.1em;
    position: absolute;
    opacity: 0;
  }
  &.link {
    transform: scale(2.5);
  }
  &#slider {
    margin: 1em;
    background-color: rgba(255, 255, 255, 0);
    border: 0.45px solid #fff; 
    border-radius: 50%;
    transform: scale(5.5);
    margin: 7px;
    svg {
      opacity: 1;
      fill: white;
      &.left {
        transform: translate(105%, 250%);
      }
      &.right {
        transform: translate(390%, 250%);
      }
    }
  }
`

const Cursor = () => {
  const [clientX, setClientX] = useState(0)
  const [clientY, setClientY] = useState(0)
  const [frameId, setFrameId] = useState(null)

  const [linkActive, setLinkActive] = useState(false)
  const [slideActive, setSlideActive] = useState(false)

  const pointer = useRef(null)

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("mousemove", onMouseMove, false)
      const mouseLinks = document.getElementsByClassName("mouse-link")
      const sliderLinks = document.getElementsByClassName("slider-swipe")
      Array.from(mouseLinks).forEach(link => {
        link.addEventListener("mouseenter", onLinkEnter, false)
        link.addEventListener("mouseleave", onLinkLeave, false)
      })
      Array.from(sliderLinks).forEach(slider => {
        slider.addEventListener("mouseenter", onSlideEnter, false)
        slider.addEventListener("mouseleave", onSlideLeave, false)
      })
      return function cleanup() {
        window.removeEventListener("mousemove", onMouseMove, false)
        window.cancelAnimationFrame(frameId)
        Array.from(mouseLinks).forEach(link => {
          link.removeEventListener("mouseenter", onLinkEnter, false)
          link.removeEventListener("mouseleave", onLinkLeave, false)
        })

        Array.from(sliderLinks).forEach(slider => {
          slider.removeEventListener("mouseenter", onSlideEnter, false)
          slider.removeEventListener("mouseleave", onSlideLeave, false)
        })
      }
    }
  })

  const onMouseMove = e => {
    setClientX(e.clientX)
    setClientY(e.clientY)
    setFrameId(window.requestAnimationFrame(moveMouse))
  }

  const moveMouse = () => {
    TweenLite.set(pointer.current, {
      left: clientX,
      top: clientY,
    })
  }

  const onLinkEnter = () => {
    setLinkActive(true)
  }

  const onLinkLeave = () => {
    setLinkActive(false)
  }

  const onSlideEnter = () => {
    setSlideActive(true)
  }

  const onSlideLeave = () => {
    setSlideActive(false)
  }

  return (
    <Container
      ref={pointer}
      className={linkActive ? "link" : null}
      id={slideActive ? "slider" : null}
    >
      <svg className="left" x="0px" y="0px" viewBox="0 0 5 6.7">
        <path
          className="st0"
          d="M0,3.3l5,3.3V0L0,3.3z M4.5,5.9L0.8,3.3l3.7-2.5L4.5,5.9z"
        />
      </svg>

      <svg className="right" x="0px" y="0px" viewBox="0 0 5 6.7">
        <path
          className="st0"
          d="M0,0v6.7l5-3.3L0,0z M0.5,0.8l3.7,2.5L0.5,5.9L0.5,0.8z"
        />
      </svg>
    </Container>
  )
}

export default Cursor
